<template>
    <el-dialog :title="detailDialogTitle" :visible.sync="detailDialogVisible" @close="closeSysMenuDetailPage"
        width="850px" top="0" center :close-on-click-modal="false">
        <el-form :model="sysMenuDto" :rules="formRules" ref="sysMenuForm" label-width="120px" size="mini">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="上级菜单" prop="parentName">
                        <el-input v-model="sysMenuDto.parentName" readonly/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="平台名称" prop="platform">
                        <el-input v-model="sysMenuDto.platform" :readonly="platformReadonly" autocomplete="off" />
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="服务名称" prop="serviceName">
                        <el-input v-model="sysMenuDto.serviceName" autocomplete="off" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="菜单名称" prop="name">
                        <el-input v-model="sysMenuDto.name" autocomplete="off" />
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="菜单图标" prop="icons">
                        <el-input v-model="sysMenuDto.icons" :readonly="iconsReadonly" autocomplete="off" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="菜单路径" prop="component">
                        <el-input v-model="sysMenuDto.component" autocomplete="off" />
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="访问路径" prop="path">
                        <el-input v-model="sysMenuDto.path" autocomplete="off" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="显示排序" prop="sortNum">
                        <el-input v-model="sysMenuDto.sortNum" autocomplete="off"  type="number"/>
                    </el-form-item>
                </el-col>
                <el-col v-if="sysMenuDto.level !== '3'" :span="10" :offset="2">
                    <el-form-item label="显示状态" prop="visible">
                        <el-radio-group v-model="sysMenuDto.visible">
                            <el-radio :label="false">不隐藏</el-radio>
                            <el-radio :label="true">隐藏</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveOrModifySysMenu" size="mini">确定</el-button>
            <el-button @click="detailDialogVisible = false" size="mini">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {addSysMenuInfo, queryMenuInfoById, updateSysMenuInfo} from "@/api/sys/menuApi";
import global from "@/components/Global";
export default {

    name: "SysMenuDetail",
    data() {

        return {

            detailDialogTitle: '新增菜单',
            detailDialogVisible: false,
            sysMenuDto : {

                name: '',
                parentId: 0,
                component: '',
                icons: 'el-icon-location',
                sortNum: '',
                path : '',
                level : '0',
                parentName: '顶级菜单',
                platform: '',
                visible: false
            },
            formRules: {

                name: [{required: true, message: '请输入菜单名称', trigger: 'blur'}],
                component: [{required: true, message: '请输入菜单路径', trigger: 'blur'}],
                icons: [{required: true, message: '请输入菜单图标', trigger: 'blur'}],
                sortNum: [{required: true, message: '请输入显示排序', trigger: 'blur'}],
                parentId: [{required: true, message: '请选择父级菜单', trigger: 'change'}],
                path: [{required: true, message: '请输入访问路径', trigger: 'blur'}],
                platform: [{ required: true, message: '请输入平台名称', trigger: 'blur'}],
                serviceName: [{required: true, message: '请输入服务名称', trigger: 'blur'}],
                visible: [{required: true, message: '请选择显示状态', trigger: 'blur'}]
            },
            platformReadonly: false,
            iconsReadonly: false
        };
    },
    methods: {

        initAddRootSysMenuDetailPage() {

            this.detailDialogVisible = true;
            this.detailDialogTitle = '新增菜单';
            this.platformReadonly = false;
            this.iconsReadonly = true;
        },
        initAddSubSysMenuDetailPage(row) {

            this.detailDialogVisible = true;
            this.detailDialogTitle = '新增菜单';
            this.platformReadonly = true;
            this.iconsReadonly = false;
            this.sysMenuDto = {

                parentId: row.id,
                parentName: row.name,
                level: (parseInt(row.level) + 1) + '',
                platform: row.platform,
                serviceName: '',
                name: '',
                icons: 'el-icon-location',
                component: '',
                path : '',
                sortNum: '',
                visible: false
            };
        },
        initEditSysMenuDetailPage(row) {

            this.detailDialogTitle = '修改菜单';
            this.detailDialogVisible = true;
            this.platformReadonly = false;
            this.iconsReadonly = false;
            let params = {

                id : row.id
            };
            queryMenuInfoById(params).then((res) => {

                if (res.code === global.response_status_success_obj) {

                    let data = res.data;
                    this.sysMenuDto = {

                        id : row.id,
                        parentId: data.parentId,
                        parentName: data.parentName,
                        level : data.level,
                        platform: data.platform,
                        serviceName: data.serviceName,
                        name: data.name,
                        icons : data.icons,
                        component : data.component,
                        path : data.path,
                        sortNum : data.sortNum,
                        visible: data.visible
                    };
                }
            }).catch(err => {

                console.error(err);
            });
        },
        closeSysMenuDetailPage() {

            this.$refs.sysMenuForm.resetFields();
        },
        saveOrModifySysMenu() {

            this.$refs["sysMenuForm"].validate((valid) => {

                if (!valid) {

                    return false;
                }
                if (this.detailDialogTitle === '新增菜单') {

                    this.addSysMenuInfo();
                } else {

                    this.updateSysMenuInfo();
                }
            });
        },
        addSysMenuInfo() {

            this.loading = true;
            addSysMenuInfo(this.sysMenuDto).then((res) => {

                this.loading = false;
                if (res.code === global.response_status_success_obj) {

                    this.detailDialogVisible = false;
                    this.$message({

                        type: 'success',
                        showClose: true,
                        message: res.msg
                    });
                    this.$emit('refreshTableData', this.sysMenuDto.parentId);
                } else {

                    this.$message({

                        type: 'error',
                        showClose: true,
                        message: res.msg
                    });
                }
            }).catch(err => {

                console.error(err);
                this.loading = false;
                this.$message({

                    type: 'error',
                    showClose: true,
                    message: '添加失败，请重试'
                });
            });
        },
        updateSysMenuInfo() {

            this.loading = true;
            updateSysMenuInfo(this.sysMenuDto).then((res) => {

                this.loading = false;
                if (res.code === global.response_status_success_obj) {

                    this.detailDialogVisible = false;
                    this.$message({

                        type: 'success',
                        showClose: true,
                        message: res.msg
                    });
                    this.$emit('refreshTableData', this.sysMenuDto.parentId);
                } else {

                    this.$message({

                        type: 'error',
                        showClose: true,
                        message: res.msg
                    });
                }
            }).catch(err => {

                this.$message({

                    type: 'error',
                    showClose: true,
                    message: '修改失败，请重试'
                });
                this.loading = false;
                console.error(err);
            });
        }
    }
}
</script>
<style scoped>
</style>