<template>
    <div class="panel-style">
        <div style="margin-bottom: 10px">
            <el-button type="primary" size="mini" @click="handleAddRootSysMenuClickEvent">添加菜单</el-button>
        </div>
        <el-table :data="tableData" ref="table" style="width: 100%;" row-key="id" size="small" stripe
                  border :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  lazy :load="loadTableTreeData" v-loading="listLoading"
                  :header-cell-style="{background:'#eef1f6', color:'#606266'}">
            <el-table-column prop="name" label="菜单名称"/>
            <el-table-column prop="component" label="菜单路径"/>
            <el-table-column prop="path" label="访问路径"/>
            <el-table-column prop="visible" label="隐藏" width="50px" align="center">
                <template slot-scope="scope">
                    <div>
                        <span v-if="scope.row.visible" style="color: #F56C6C">是</span>
                        <span v-else style="color: #67C23A">否</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="sortNum" label="顺序" width="60px"/>
            <el-table-column prop="platform" label="平台名称" width="120px"/>
            <el-table-column prop="serviceName" label="服务名称" width="120px"/>
            <el-table-column prop="icons" label="菜单图标" width="120px"/>
            <el-table-column prop="opt" label="操作" width="100px">
                <template slot-scope="scope">
                    <div class="option-btn-div">
                        <i class="option-btn-normal el-icon-edit" @click="handleEditSysMenuClickEvent(scope.$index, scope.row)"/>
                        <i class="option-btn-danger el-icon-delete" @click="handleDeleteSysMenuClickEvent(scope.$index, scope.row)"/>
                        <i v-if="scope.row.level!=='3'" class="option-btn-normal doart-icon-hierarchy"
                            @click="handleAddSubSysMenuClickEvent(scope.$index, scope.row)"/>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <sys-menu-detail ref="sysMenuDetailPage" @refreshTableData="refreshTableData"/>
    </div>
</template>
<script>
import global from "@/components/Global";
import {deleteSysMenuInfo, queryMenuInfoByParentId} from '@/api/sys/menuApi';
import SysMenuDetail from "@/views/sys/menu/sys-menu-detail";
export default {

    name: "SysMenuList",
    components: {

        SysMenuDetail
    },
    data() {

        return {

            tableData : [],
            maps: new Map(),
            listLoading : false
        };
    },
    methods : {

        initPage() {

            this.listLoading = true;
            let params = {

                id: 0
            };
            queryMenuInfoByParentId(params).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableData = res.data;
                } else {

                    this.$message({

                        type: 'error',
                        showClose: true,
                        message: '加载失败，请重试'
                    });
                }
            }).catch(err => {

                this.listLoading = false;
                this.$message({

                    type: 'error',
                    showClose: true,
                    message: '加载失败，请重试'
                });
                console.error(err);
            });
        },
        handleAddRootSysMenuClickEvent() {

            this.$refs.sysMenuDetailPage.initAddRootSysMenuDetailPage();
        },
        handleAddSubSysMenuClickEvent(index, row) {

            this.$refs.sysMenuDetailPage.initAddSubSysMenuDetailPage(row);
        },
        handleEditSysMenuClickEvent(index, row) {

            this.$refs.sysMenuDetailPage.initEditSysMenuDetailPage(row);
        },
        handleDeleteSysMenuClickEvent(index, row) {

            this.$confirm('是否确定删除？', '提示', {

                closeOnClickModal: false,
                type: 'warning'
            }).then(() => {

                let params = {

                    id : row.id
                };
                deleteSysMenuInfo(params).then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.$message({

                            message: res.msg,
                            type: 'success',
                            showClose: true
                        });
                        this.refreshTableData(row.parentId);
                    } else {

                        this.$message({

                            message: res.msg,
                            type: 'error',
                            showClose: true
                        });
                    }
                }).catch(err => {

                    console.error(err);
                    this.$message({

                        message: '删除失败，请重试',
                        type: 'error',
                        showClose: true
                    });
                });
            });
        },
        loadTableTreeData(tree, treeNode, resolve) {

            // 懒加载查询子菜单
            this.maps.set(tree.id, {tree, treeNode, resolve});
            this.listLoading = true;
            let params = {

                id : tree.id
            };
            queryMenuInfoByParentId(params).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    resolve(res.data);
                } else {

                    // 去掉懒加载的 加载中 图标
                    resolve([]);
                }
            }).catch(err => {

                console.error(err);
                resolve([]);
            });
        },
        refreshTableData(param) {

            // 操作子菜单后刷新树结构
            if (this.maps.has(param)) {

                const {tree, treeNode, resolve} = this.maps.get(param);
                this.$set(this.$refs.table.store.states.lazyTreeNodeMap, param, []);
                this.loadTableTreeData(tree, treeNode, resolve);
            } else {

                if (param === 0) {

                    this.initPage();
                } else {

                    let params = {

                        parentId : param
                    };
                    queryMenuInfoByParentId(params).then((res) => {

                        this.$set(this.$refs.table.store.states.lazyTreeNodeMap, param, res.data);
                    }).catch(err => {

                        console.error(err);
                    });
                }
            }
        }
    },
    mounted() {

        this.initPage();
    }
}
</script>
<style scoped lang="scss">
.option-btn-div {

    display: flex;
    justify-content: flex-start;
    gap: 10px;
    font-size: 18px;
}
@mixin option-btn {

    cursor: pointer;
}
.option-btn-normal {

    @include option-btn;
    color: #409EFF;
}
.option-btn-danger {

    @include option-btn;
    color: #F56C6C;
}
.doart-icon-hierarchy {

    height: 18px;
    width: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../assets/icon/button/hierarchy.svg');
}
</style>
